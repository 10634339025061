/*
 * 2020-2024 (C) - OneOverZero AG - MR,FG
 */
/**
 * This entrypoint loads the necessary polyfills to support all the browsers.
 * the IE11 polyfills are packaged in a separate chunk because we don't want to
 * pollute the modern browsers with unnecessary patchy code.
 *
 */

import Bowser from 'bowser';
import 'core-js/features/promise';

//Ensure that all browsers support ResizeObserver. This is usually loaded as a 'ponyfill'
//but useSize requires it...
import ResizeObserver from 'resize-observer-polyfill';

//@ts-ignore
if (!window.ResizeObserver) {
  //@ts-ignore
  window.ResizeObserver = ResizeObserver;
}

//detect Internet Explorer
const browser = Bowser.getParser(window.navigator.userAgent);

//inject a CSS class on the body to indicate the browser name
const browserClass =
  'browser-' + browser.getBrowserName().toLowerCase().replace(/ /g, '-');
document.body.classList.add(browserClass);

const IEPolyfills = browser.isBrowser('internet explorer')
  ? import(
      /* webpackChunkName: "ie-unsupported" */ './ie-polyfills'
    ).then(() => {})
  : Promise.resolve();

IEPolyfills.then(() => import('./index-application'));
